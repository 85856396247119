<template>
  <div>
    <div class="project-progress py-2 ">
      <div class="project-progress-box" v-bind:class="setProgressColor()">
        <div class="project-progress-bar" :style="{ width: width + '%' }">
          <div class="project-progress-value">{{ percentext }}%</div>
        </div>
      </div>
    </div>
    <div
      class="
        project-amount
        fs-5
        gap-1
        d-flex
        flex-wrap
        justify-content-between
        py-3
        gap-3
      "
    >
      <div
        class="
          total-amount
          d-flex
          flex-fill
          justify-content-between
          align-items-center
          edc-border-primary
          rounded-20
          py-1
          px-3
          bg-white
        "
      >
        <div
          class="project-amount-icon bg-white p-1 edc-border-primary rounded-10"
        >
          <i class="fas fa-coins text-muted"></i>
        </div>
        <div class="project-amount-text me-2 flex-fill">
          <span class="d-block fs-6">{{ $t("label.amount_collected") }}</span>
          <span class="d-block edc-color-green d-flex">
            <count-up :endVal="parseInt(details.total)">
              {{ details.currency }}
            </count-up>
          </span>
        </div>
      </div>
      <div
        class="
          total-residual
          d-flex
          flex-fill
          justify-content-between
          align-items-center
          edc-border-primary
          rounded-20
          py-1
          px-3
          bg-white
        "
      >
        <div
          class="project-amount-icon bg-white p-1 edc-border-primary rounded-10"
        >
          <i class="fas fa-coins text-muted"></i>
        </div>
        <div class="project-amount-text me-2 flex-fill">
          <span class="d-block fs-6">{{ $t("label.remaining_amount") }}</span>
          <span class="d-block edc-color-green d-flex">
            <count-up :endVal="parseInt(details.residual)">
              {{ details.currency }}
            </count-up>
          </span>
        </div>
      </div>
      <div
        v-if="amount"
        class="
          total-residual
          flex-fill
          d-flex
          justify-content-between
          align-items-center
          edc-border-primary
          rounded-20
          py-1
          px-3
          bg-white
        "
      >
        <div
          class="
            project-amount-icon
            bg-white
            p-1
            edc-border-primary
            rounded-10
            edc-color-blue
          "
        >
          <i class="fas fa-coins"></i>
        </div>
        <div class="project-amount-text me-2 flex-fill">
          <span class="d-block fs-6">{{ $t("label.your_donate") }}</span>
          <span class="d-block edc-color-blue d-flex">
            <div class="d-flex">
              <count-up :endVal="parseInt(amount)">
                {{ details.currency }}
              </count-up>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "progress-and-details",
  props: {
    project_id: {
      type: Number,
      default: 0,
    },
    details: null,
    amount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      width: this.details.percent,
      percentext: this.details.percent,
      percentage: this.details.percent,
    };
  },
  methods: {
    setProgressColor() {
      if (this.width >= 80) {
        return "progress-color-green";
      } else if (this.width >= 50) {
        return "progress-color-yellow";
      } else if (this.width >= 30) {
        return "progress-color-blue";
      } else {
        return "progress-color-red";
      }
    },
    progressAnimate(newNumber, oldNumber) {
        let _this = this;

        let interval = setInterval(function () {
        if (oldNumber > newNumber) {
          _this.width--;
          if (_this.width <= newNumber) {
            clearInterval(interval);
          }
        } else {
          _this.width++;
          if (_this.width >= newNumber) {
            clearInterval(interval);
          }
        }
      }, 1000);
    },
    loadProgressBar() {
        let _this = this;
        let loadingInterval = setInterval(function () {
        if (_this.project_id) {
          axios
            .get("get-progress", { params: { id: _this.project_id } })
            .then((response) => {
              if (response.status === 200) {
                _this.props.details = response.data;
                _this.$root.$emit("updateProjectAmountDetails",response.data,_this.project_id);
              }
              if (_this.props.details.percentage >= 100) {
                clearInterval(loadingInterval);
              }
            })
            .catch(() => {});
        }
      }, 1200000);
    },
  },
  created() {
    this.loadProgressBar();
  },
  watch: {
    percentage: function (newValue, oldValue) {
      this.progressAnimate(newValue, oldValue);
    },
    width: function (newValue) {
      if (newValue > 100) {
        this.percentext = "100+";
      } else {
        this.percentext = newValue;
      }
    },
  },
};
</script>