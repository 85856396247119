import { render, staticRenderFns } from "./WishlistPage.vue?vue&type=template&id=d12b4de0&"
import script from "./WishlistPage.vue?vue&type=script&lang=js&"
export * from "./WishlistPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports