<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <wish-list></wish-list>
      </div>
    </div>
  </div>
</template>
<script>
import WishList from "@/components/WishList";
export default {
  name: "WishlistPage",
  components: { WishList },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.wishlist") + " | %s",
      meta: [
        { property: "og:title", content: this.$t("title.wishlist") },
      ],
    };
  },
};
</script>