<template>
  <div>
    <loading :isShowLoading="isLoading"></loading>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <div
      v-if="isShow"
      class="
        edc-content-round
        my-donations-content
        rounded-50
        col-md-8 col-sm-12
        m-auto
        p-3 p-lg-5
      "
    >
      <div
        class="
          my-donations-content-head
          border-bottom
          py-2
          d-flex
          align-items-center
        "
      >
        <h1 class="h4">مرحباً</h1>
        <div class="mx-2">
          <user-avatar :size="50" :isName="true"></user-avatar>
        </div>
        <h1 class="h4">هذة قائمة بالمشاريع التي تتابعها</h1>
      </div>
      <!--my-donations-projects-->
      <div class="my-donations-projects mt-5 mt-sm-1">
        <div>
          <div v-if="isEmpty" class="alert alert-danger">
            {{ $t("message.not_project_found") }}
          </div>
          <!--my-donations-projects-item-->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="
              my-donations-projects-item
              d-lg-flex
              justify-content-lg-between
              align-items-start
              border-bottom
              my-4
              py-3
            "
          >
            <div class="thumable me-2 col-lg-4">
              <router-link :to="{ path: 'products/' + item.slug }">
                <div
                  v-if="item.is_completed"
                  class="project-completed fs-7 rounded-pill edc-color-green"
                >
                  <i class="fas fa-check"></i>
                  <span class="d-inline ms-2">{{ $t("btn.donated") }}</span>
                </div>
                <img
                  :src="item.image.thumbnail"
                  lazysizes
                  :data-src="item.image.thumbnail"
                  class="project-list-img img-fluid"
                  alt=""
                  srcset=""
                />
              </router-link>
            </div>
            <div class="details flex-fill mt-3 mt-lg-0">
              <h2 class="text-end fs-5 fw-bold mb-4 mx-2">
                <router-link :to="{ path: 'products/' + item.slug }">{{
                  item.title
                }}</router-link>
              </h2>
              <!--progress-->
              <progress-and-details
                :project_id="parseInt(item.id)"
                :details="item.meta"
              ></progress-and-details>

              <!--// progress-->
              <!--project value-->
              <div
                class="project-value fs-5 d-flex justify-content-between py-3"
                v-if="item.meta.target > 0"
              >
                <div class="project-value-title edc-color-primary fw-bold">
                  {{ $t("label.project_value") }}
                </div>
                <div class="project-value-money edc-color-primary fw-bold">
                  {{ item.meta.target }} {{ item.currency }}
                  <i class="far fa-money-bill-alt"></i>
                </div>
              </div>
              <!--//project value-->
              <div class="d-flex justify-content-between align-items-center">
                <!--buttons-->
                <div
                  v-if="item.reports"
                  class="
                    buttons
                    mt-4
                    d-lg-flex
                    justify-content-lg-between
                    align-items-lg-center
                  "
                >
                  <a
                    href="#"
                    class="
                      rounded-pill
                      d-block d-lg-inline
                      btn
                      edc-btn-primary
                      text-white
                      fs-5
                      py-2
                      px-4
                      text-center
                    "
                  >
                    {{ $t("label.download_report") }}
                    <i class="fas fa-cloud-download-alt"></i>
                  </a>
                </div>
                <div
                  v-if="item.total_user_donate"
                  :title="$t('label.your_total_donate')"
                  class="
                    me-2
                    edc-bg-green
                    text-white
                    fs-6
                    py-1
                    px-2
                    rounded-pill
                    d-flex
                  "
                >
                  <i class="fas fa-hand-holding-usd ms-2"></i>
                  <span> {{ $t("label.your_total_donate") }} </span>
                  <span
                    ><count-up :endVal="parseInt(item.total_user_donate)">
                      {{ item.currency }}
                    </count-up></span
                  >
                </div>

                <!--//buttons-->
              </div>
            </div>
          </div>
          <!--//my-donations-projects-item-->
          <!--my-donations-loadmore-->
          <loading-page :isShow="isLoading"></loading-page>

          <div
            v-if="isShowLoadMoreButton"
            class="my-donations-loadmore my-3 py-3 text-center fs-5 fw-bold"
          >
            <a href="#" @click.prevent="loadMore()" class="edc-color-primary"
              ><span class="ms-4 d-inline"> {{ $t("btn.load_more") }}</span>
              <i class="fas fa-chevron-down"></i
            ></a>
          </div>
          <!--//my-donations-loadmore-->
        </div>
      </div>
      <!--//my-donations-projects-->

      <!--my-donations-content-bottom-->
      <div
        class="
          my-donations-content-bottom
          mx-5
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <router-link
          :to="{ path: '/projects' }"
          class="btn edc-btn-outline-gray rounded-pill py-3 px-5 me-5"
        >
          {{ $t("btn.show_project") }}</router-link
        >
        <router-link
          :to="{ path: '/' }"
          class="btn edc-btn-primary rounded-pill py-3 px-5 text-white me-3"
        >
          <i class="fas fa-home ms-3"></i>

          {{ $t("btn.home") }}
        </router-link>
      </div>
      <!--//my-donations-content-bottom-->
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import ProgressAndDetails from "@/components/ProgressAndDetails";
import axios from "axios";
import UserAvatar from "./UserAvatar.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import vueLazysizes from "vue-lazysizes";

export default {
  components: {
    Loading,
    ProgressAndDetails,
    UserAvatar,
    LoadingPage,
  },
  props: [],
  data() {
    return {
      isEmpty: false,
      isShow: false,
      items: [],
      auth: {},
      offset: 0,
      limit: 5,
      isLoading: true,
      isShowLoadMoreButton: false,
      errorMessage: "",
    };
  },
  methods: {
    async get_list() {
      await axios
        .get("get-wishlists", {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
        })
        .then((response) => {
          this.isShow = true;
          for (let i = 0; i < response.data.projects.length; i++) {
            //console.log(response.data.projects[i]);
            this.items.push(response.data.projects[i]);
          }
          if (response.data.projects.length <= 0) {
            this.isShowLoadMoreButton = false;
          }
        })
        .catch((error) => {
          this.isShow = false;
          this.errorMessage = error.response.data;
        })
        .finally(() => {
          this.isLoading = false;
          if (this.items.length <= 0) {
            this.isEmpty = true;
          }
        });
    },
    async loadMore() {
      this.offset++;
      await this.get_list();
    },
  },
  created() {
    this.get_list();
    // this.auth = Auth.getAUthData();
  },
    mounted() {
        let _this = this;
        this.$root.$on("authLogin",(payload)=>{
            _this.get_list();
        });
        this.$root.$on("authLogout",()=>{
            _this.items = [];
        });
    },
  directives: {
    lazysizes: vueLazysizes
  },
  watch: {
    items: function (newData) {
      if (newData.length > 0) {
        this.isShowLoadMoreButton = true;
      }
    },
  },
};
</script>